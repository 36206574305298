import getConfig from 'next/config'

const {serverRuntimeConfig} = getConfig()

const circleBranch = process.env.CIRCLE_BRANCH

const envObj = {
  PORT: process.env.PORT || 3000,
  API_URL: process.env.API_URL || '',
  APP_URL: process.env.APP_URL,
  EMAIL_API_URL: process.env.EMAIL_API_URL,
  SLACK_API_URL: process.env.SLACK_API_URL,
  BLOG_API_KEY: process.env.BLOG_API_KEY,
  DEMO_API_KEY: process.env.DEMO_API_KEY,
  PDPACORE_API_URL: serverRuntimeConfig.PDPACORE_API_URL,
  PDPACORE_API_KEY: serverRuntimeConfig.PDPACORE_API_KEY,
  SALES_EMAIL: process.env.SALES_EMAIL,

  RECAPTCHA_KEY: process.env.RECAPTCHA_KEY,
  GTM_CONTAINER_ID: process.env.GTM_CONTAINER_ID,

  CONSENT_WOW_API_URL: process.env.CONSENT_WOW_API_URL,
  CONSENT_WOW_MARKETING_CONSENT_PURPOSE_ID:
    process.env.CONSENT_WOW_MARKETING_CONSENT_PURPOSE_ID,
  CONSENT_WOW_CONTACT_API_KEY: process.env.CONSENT_WOW_CONTACT_API_KEY,

  SLACK_WEBHOOK_URL: process.env.SLACK_WEBHOOK_URL,

  IS_DEV: circleBranch !== 'production',
}

export default {...envObj}
